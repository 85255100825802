.date-range-selector {
  position: relative;
}

.custom-range-picker {
  position: absolute;
  top: 100%; /* position below the dropdown */
  left: 0;
  z-index: 1; /* Ensure it's on top of other elements */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

// .filter-card {
//   position: relative;
//   padding: 20px;
// }

.echarts-container{
  position: relative;
  height: 400px;

  .prev-button{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 1;
  }

  .next-button{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 2.5rem;
    height: 2.5rem;
    z-index: 1;
  }
}

.date-dropdown-menu{
  width:235px;
}

.date-simplebar{
  max-height: 200px;
}

.filter-card-body{
  height: 382px;
}