@import './icons.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import './bootstrap.scss';
@import './app.scss';
@import 'primereact/resources/themes/lara-light-blue/theme.css';
@import 'primereact/resources/primereact.min.css';

:root {
  // Color palette
  --primary-color: #556ee6;
  --secondary-color: #74788d;
  --success-color: #34c38f;
  --info-color: #50a5f1;
  --warning-color: #f1b44c;
  --danger-color: #f46a6a;
  --light-color: #eff2f7;
  --placeholder-grey: #848984;
  --dark-color: #343a40;
  --badge-primary: #2196f3;

  // Text colors
  --text-primary: #495057;
  --text-secondary: #74788d;
  --text-muted: #7a7f9a;

  // Background colors
  --bg-primary: #f8f9fa;
  --bg-secondary: #ffffff;
  --bg-light: #eff2f7;
  --bg-message-green: #34c759;

  // Border colors
  --border-color: #e9e9ef;
  --border-radius: 0.25rem;

  // Fonts
  --font-family-sans-serif: 'Poppins', sans-serif;
  --font-family-monospace: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
  --font-size-base: 0.875rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 600;

  // Spacing
  --spacer: 1rem;

  // Shadows
  --box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  --box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);

  // Z-index
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;

  // Transitions
  --transition-base: all 0.2s ease-in-out;

  // Dark theme
  &.theme-dark {
    --primary-color: #556ee6;
    --secondary-color: #6c757d;
    --success-color: #34c38f;
    --info-color: #50a5f1;
    --warning-color: #f1b44c;
    --danger-color: #f46a6a;
    --light-color: #383838;
    --dark-color: #eff2f7;

    --text-primary: #e9ecef;
    --text-secondary: #ced4da;
    --text-muted: #adb5bd;

    --bg-primary: #2a3042;
    --bg-secondary: #32394e;
    --bg-light: #383e52;

    --border-color: #424e5a;
  }

  // Blue theme
  &.theme-blue {
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    // ... (adjust other colors as needed)

    --bg-primary: #e6f2ff;
    --bg-secondary: #ffffff;
  }

  // Green theme
  &.theme-green {
    --primary-color: #28a745;
    --secondary-color: #6c757d;
    // ... (adjust other colors as needed)

    --bg-primary: #e6fff0;
    --bg-secondary: #ffffff;
  }
}

// SCSS variables that reference CSS custom properties
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$success-color: var(--success-color);
$info-color: var(--info-color);
$warning-color: var(--warning-color);
$danger-color: var(--danger-color);
$light-color: var(--light-color);
$dark-color: var(--dark-color);

$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
$text-muted: var(--text-muted);

$bg-primary: var(--bg-primary);
$bg-secondary: var(--bg-secondary);
$bg-light: var(--bg-light);

$border-color: var(--border-color);
$border-radius: var(--border-radius);

$font-family-sans-serif: var(--font-family-sans-serif);
$font-family-monospace: var(--font-family-monospace);
$font-size-base: var(--font-size-base);
$font-weight-normal: var(--font-weight-normal);
$font-weight-medium: var(--font-weight-medium);
$font-weight-bold: var(--font-weight-bold);

$spacer: var(--spacer);

$box-shadow: var(--box-shadow);
$box-shadow-sm: var(--box-shadow-sm);
$box-shadow-lg: var(--box-shadow-lg);

$zindex-dropdown: var(--zindex-dropdown);
$zindex-sticky: var(--zindex-sticky);
$zindex-fixed: var(--zindex-fixed);
$zindex-modal-backdrop: var(--zindex-modal-backdrop);
$zindex-modal: var(--zindex-modal);
$zindex-popover: var(--zindex-popover);
$zindex-tooltip: var(--zindex-tooltip);

$transition-base: var(--transition-base);

body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}

//Chat Scrollbar
.ps__rail-y {
  // z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}

// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}

.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

/*  tui charts**/
.tui-chart .tui-chart-chartExportMenu-area {
  z-index: 1 !important;
}
