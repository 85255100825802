//
// _pagination.scss
//

// Pagination rounded (Custom)
// .pagination-rounded {
//   .page-link {
//     border-radius: 30px !important;
//     margin: 0 3px !important;
//     border: none;
//     width: 2rem;
//     height: 2rem;
//     padding: 0;
//     text-align: center;
//     line-height: 2rem;
//   }
// }

.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: auto; /* Allow the width to adjust based on content */
    min-width: 2rem; /* Ensure a minimum width */
    height: 2rem;
    padding: 0 0.5rem; /* Add some padding to accommodate larger numbers */
    text-align: center;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
