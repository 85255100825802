//
// Chat.scss
//

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
    max-width: 260px;
  }
  @media (min-width: 1200px) {
    min-width: 380px;
  }
}

.chat-left-div {
  background-color: #f9fafb;
  padding: 0.5rem 1.1rem 0.5rem 0.5rem;
  border: 2px solid white;

  .nav-custom-links {
    cursor: pointer;
    background-color: aliceblue;
  }
}

.button-hover:hover {
  background-color: #e0e0e0;
  transition: background-color 0.3s ease;
}

.add-note-container {
  .note-input {
    resize: none;
    height: 90px;
  }
}
.note-list-container {
  max-height: 39vh;
  overflow: hidden;

  &.expanded {
    overflow: auto;
  }

  .note-list-container:not(.expanded) {
    height: 300px; // Adjust as needed
  }
}
.note-list-scroll {
  height: 60%;
  position: absolute;
  width: -webkit-fill-available;
  margin: 0rem 1.3rem 0rem 0rem;
}

.nav-link-custom {
  cursor: pointer;
  background-color: aliceblue;
}

.chat-rightsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
  }

  @media (min-width: 1200px) {
    min-width: 380px;
    max-width: 380px;
    // max-height: 680px;
  }

  .nav.custom-filter-navebar {
    .custom-filter-navitem {
      align-self: baseline;
      .custom-filter-navlink {
        padding: 1px 10px;
        border: 1px, solid $primary;
        border-radius: 50px;
      }
      .active-tab {
        background-color: $primary; /* Change this to your desired color */
        color: white;
      }
    }
  }

  .chat-list {
    .chat {
      &:hover {
        background-color: #deedfb;
      }
      &.active {
        // background-color: #f8f9fa; /* Background color for selected chat */
        box-shadow: 5px 0.75rem 1.5rem rgb(220 220 220 / 44%);
      }
    }
  }
}
.chat-rightsidebar-scroll {
  height: 410px;
  min-height: 410px;
}

.chat-right-div {
  background-color: #f9fafb !important;
  padding: 0.5rem !important;
  border: 2px solid white !important;
}

.inbox {
  position: fixed;
  bottom: 60px;
  top: 70px;
  width: -webkit-fill-available;
}

.user-chatbox {
  @media (min-width: 992px) {
    min-width: 260px;
  }
  @media (min-width: 1200px) {
    min-width: 380px;
  }
  .user-chatbox-card {
    margin-bottom: 0;
    height: 100%;
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.search-box {
  .form-control {
    border-radius: 30px;
    padding-left: 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}

.chat-list {
  margin: 0;
  li {
    &.active {
      a {
        background-color: #dce6f2 !important;
        border-color: transparent !important;
        box-shadow: 5px 0.75rem 1.5rem rgb(131 133 136 / 7%);
        border-radius: 0.5rem;
        transition: all 0.4s ease; // Apply smooth transition for active state
      }
    }
    a {
      display: block;
      padding: 10px 16px;
      color: $gray-600;
      border-radius: 4px;
      transition: background-color 0.4s ease, border-color 0.4s ease, box-shadow 0.4s ease,
        border-radius 0.4s ease; // Transition smooth for all effects

      &:hover {
        background-color: #ffffff !important;
        border-color: transparent !important;
        border-radius: 0.5rem;
        box-shadow: 5px 0.75rem 2.5rem rgb(176 180 182 / 47%);
      }
    }
  }
}

.user-chat-nav {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color;
    }
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;

    .title {
      background-color: $card-bg;
      position: relative;
      z-index: 0;
      padding: 6px 24px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
      z-index: 0;
    }
    .badge {
      font-size: 12px;
    }
  }
  .conversation-list {
    margin-bottom: 24px;
    display: inline-block;
    position: relative;

    .ctext-wrap {
      padding: 12px 24px;
      background-color: var(--blue-100);
      border-radius: 8px 8px 8px 0px;
      overflow: hidden;
      color: var(--blue-800);

      .conversation-name {
        font-weight: $font-weight-semibold;
        color: $primary;
        margin-bottom: 4px;
      }
    }

    .dropdown {
      float: right;
      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;
        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .dropdown-menu {
        box-shadow: $box-shadow;
        border: 1px solid $border-color;
      }
    }
    .chat-time {
      font-size: 12px;
    }
  }

  .right {
    .conversation-list {
      float: right;
      .ctext-wrap {
        background-color: var(--teal-100);
        text-align: right;
        border-radius: 8px 8px 0px 8px;
        color: var(--teal-900);
      }
      .dropdown {
        float: left;
      }

      &.last-chat {
        .conversation-list {
          &:before {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .last-chat {
    .conversation-list {
      &:before {
        content: '\F0009';
        font-family: 'Material Design Icons';
        position: absolute;
        color: $primary;
        right: 0;
        bottom: 0;
        font-size: 16px;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }
  z-index: 0;
}

.chat-convo-scroll {
  height: 486px;
  min-height: 538px;
  position: relative;
  z-index: 1;
}

.right-18 {
  right: 18px;
}

.chat-input-section {
  z-index: 1;
  width: 97%;
  position: absolute;
  border-top: 1px solid #eff2f7;
  bottom: 26px;
  background-color: #ffffff;
  margin: 0.5rem;
  box-shadow: 0px 0px 13px rgb(176 176 176 / 70%);
  border-radius: 0.3rem;

  .chat-input-wrapper {
    position: relative;
  }

  .chat-input {
    border: none;
    padding-right: 10px; /* Adjust padding to ensure space for buttons */
    width: 100%;
  }

  .chat-input-links {
    position: absolute;
    display: flex;
    align-items: center;

    .list-inline-item {
      display: inline-block;
      margin-right: 5px;
      i,
      label {
        cursor: pointer;
      }
    }

    .emoji-picker {
      position: absolute;
      bottom: 50px; /* Adjust based on your design */
      right: 0;
      z-index: 10;
    }
  }

  @media (max-width: 950px) {
    .chat-input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .chat-input {
      padding-right: 60px; /* Reduce padding to make room for the button */
    }

    .chat-input-links {
      right: 10px;
    }
  }

  @media (max-width: 767.98px) {
    .chat-input {
      padding-right: 50px;
    }

    .chat-input-links {
      right: 8px;

      .emoji-picker {
        bottom: 40px; /* Adjust for smaller screens */
      }
    }
  }

  @media (max-width: 575.98px) {
    .chat-input-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .chat-input {
      padding-right: 40px;
      flex-grow: 1;
    }

    .chat-input-links {
      position: static;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

.chat-send {
  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 575.98px) {
    min-width: auto;
    width: 100%;
  }
}

.quick-message-card {
  position: absolute;
  bottom: 100%; /* Position the card above the button */
  right: 0;
  width: 200px; /* Adjust the width as needed */
  margin-bottom: 10px; /* Space between button and card */
  background-color: #f8f9fa; /* Match the UI background color */
  border: 1px solid #dee2e6; /* Border color to match UI */
  border-radius: 4px; /* Rounded corners */

  .list-group-item {
    padding: 10px; /* Add padding for spacing */
    cursor: pointer;
    color: #495057; /* Text color to match UI */
    background-color: transparent; /* Background color of item */

    &:hover {
      background-color: #e9ecef; /* Hover background color to match UI */
    }
  }
}

.chat-container {
  height: 100%; /* or a fixed height, e.g., 600px */
  display: flex;
  flex-direction: column;
}

.chat-conversation {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  background-color: var(--purple-50);
}

.chat-conversation::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../../images/inbox/whatsapp-background.png');
  background-repeat: repeat;
  background-size: auto;
  opacity: 0.1; /* Adjust as needed */
  z-index: 0;
}

.scroll-button {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  border: none;
  border-radius: 50px !important;
  z-index: 2;
}

/* Ensure PerfectScrollbar doesn't hide the background */
.ps__rail-y {
  background-color: transparent !important;
}

.quick-message-item {
  padding: 10px;
  margin: 0 -10px;
  cursor: pointer;
}

.quick-message-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.conversation-info {
  .info-icon {
    color: var(--gray-600);
  }
  .info-title {
    color: var(--gray-400);
    margin-right: 0.3rem;
  }
  .info-value {
    color: var(--gray-600);
  }
}

.media-close {
  height: 1.2rem;
  width: 1.2rem;
}

.media-wrapper {
  position: relative;
  display: inline-block;
  padding-left: 0.7rem;
}

.close-button {
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: -0.3rem;
}

.media-wrapper:hover .close-button {
  opacity: 1;
  pointer-events: auto;
}

.inbox-right-tab {
  padding: 0rem 0.5rem;
  height: 47.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-edit-input {
  border: none;
  border-bottom: 1px solid var(--gray-300);
  border-radius: 0;
  box-shadow: none;
  padding: 0.5rem 0.3rem 0.3rem 0.2rem;
  width: 16rem;
}

.avatar-div {
  .chat-edit-input {
    width: 14rem;
  }
}

.info-value {
  .save-cancel-chips {
    top: -18px !important;
    right: 10px !important;
    display: flex;
    div {
      display: flex;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 15px;
    }
  }
}

.mention {
  background-color: #e8f0fe;
  color: #1a73e8;
  padding: 2px 4px;
  border-radius: 2px;
}

.note-editor {
  .ql-container {
    height: 7rem;
  }
  .ql-mention-list-container {
    max-height: 20rem;
  }
}

.selected-note {
  background-color: #dce6f2;
  border-radius: 1rem;
}

.timeline-con-div {
  background-color: var(--blue-100);
}

.timeline-time {
  color: var(--placeholder-grey);
}

.placeholder-color {
  color: var(--placeholder-grey);
}

.quick-message-listbox {
  position: absolute;
  bottom: 0.5rem;
}

.conversation-scroll {
  height: 43rem;
  min-height: 43rem;
}
