.select-custom .react-select__control {
    background-color: #333336;
    border: 1px solid #434344;
    color: #fff;
  }
  
  .select-custom .react-select__single-value {
    color: #fff;
  }
  
  .select-custom .react-select__menu {
    background-color: #333336;
    color: #fff;
  }
  
  .select-custom .react-select__option {
    background-color: #333336;
    color: #fff;
  }
  
  .select-custom .react-select__option--is-focused {
    background-color: #434344;
  }
  
  .select-custom .react-select__option--is-selected {
    background-color: #565658;
  }
  