.channel-grid {
    .channel-card {
      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 2rem 0;
      }
    }
  
    .channel-icon-wrapper {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .channel-icon {
      font-size: 2rem;
    }
  
    .channel-name {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  
    .channel-campaigns {
      font-size: 0.9rem;
      margin-bottom: 1.5rem;
    }
  
    .channel-button {
      padding: 0.5rem 1.5rem;
      min-width: fit-content;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }