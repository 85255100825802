.custom-accordion {
  .accordion-item {
    background-color: transparent;
  }

  .accordion-button {
    background-color: transparent;
    box-shadow: none;

    &:not(.collapsed) {
      color: inherit;
      background-color: transparent;
    }

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}
