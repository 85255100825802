.filter-main {
  box-sizing: border-box;
}
.and-btn-border {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
  border-width: 1px 0px 1px 1px;
  background: #f6f7f8;
}
.or-btn-border {
  border-radius: 0 0.5rem 0.5rem 0 !important;
  border-width: 1px 1px 1px 0px;
  background: #f6f7f8;
}
.active-btn {
  border-width: 1px 1px 1px 1px !important;
  border-color: blue !important;
  color: blue !important;
  background: none !important;
}

.border-light-grey {
  border-color: #d7dae2 !important;
}

.background-light-grey {
  background: #f6f7f8;
}

.field-select {
  width: 20rem;
  border-radius: 0.5rem;
}

.border-none {
  border: none;
}

.error-border {
  div {
    border-color: red !important;
  }
}
