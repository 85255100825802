//
// Contacts.scss
//

.contact-links {
  a {
    color: $body-color;
  }
}

// profile

.profile-user-wid {
  margin-top: -26px;
}

.more-options {
  height: 2.7rem;
  width: 2.7rem;
  border-radius: 50%;
  background-color: #cfe2ff;
  border: none;
}

.more-options .p-dropdown-trigger {
  display: none;
}

.view-offcanvas-body {
  overflow-x: hidden;
}

.text-field {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
