.modal-loader {
  position: absolute;
  top: 50%;
  z-index: 10;
  left: 45%;
}

.width-50rem {
  width: 50rem !important;
}

.width-5rem {
  width: 5rem !important;
}
.width-21rem {
  width: 21rem !important;
}

.active-link i {
  color: white !important;
  /* Add any other styles you want for the active link */
}

.border-darkgrey {
  border-color: #c0c0c0 !important;
}

.form-control-select {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select-border-bottom {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
}

.county-code-select .select2-selection__control {
  height: 2.25rem !important;
}

.county-code-select .css-yk16xz-control {
  min-height: 2.25rem !important;
}

.select-border-bottom-none {
  border: none !important;
  border-radius: 0 !important;
}

.download-csv-btn {
  background-color: #556ee6;
  color: white;
}

.download-csv-btn:hover {
  background-color: white;
  color: #556ee6;
  border-color: #556ee6;
}
.select2-selection {
  width: 8rem;
}
.width-8rem {
  width: 8rem !important;
}

.width-10rem {
  width: 10rem !important;
}

.width-15rem {
  width: 15rem !important;
}

.display-none {
  display: none !important;
}

.me-2rem {
  margin-right: 2rem;
}

.template-text {
  background-color: #e0f1ff;
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.loader-div {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  right: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Transparent white background */
  width: 100%;
  height: 100%;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.inbox-modal-list {
  padding: 10px;
  border: 1px solid #e4e4e4;
  transition: background-color 0.3s ease;
}

.inbox-modal-list:not(:last-child) {
  border-bottom: none; /* Remove border at the bottom for all except the last item */
}

.inbox-modal-list:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.inbox-list-modal {
  height: 24rem;
  overflow-y: scroll;
}

.whatsapp-template {
  .modal-content {
    width: 35rem;
  }
}

.whatsapp-template-body {
  height: 34rem;
  overflow-y: scroll;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 1000; /* Adjust as needed */
  background-color: white; /* Ensure button is visible on any background */
}

.cursor-pointer {
  cursor: pointer;
}

.call-dialer {
  background-color: #495057 !important;
}
.custom-toast-content {
  display: flex;
  align-items: center;
}

.logo {
  flex: 0 0 auto; /* Prevent logo from growing or shrinking */
  margin-right: 10px; /* Adjust margin as needed */
}

.message-details {
  flex: 1; /* Expand to fill remaining space */
}
.from {
  font-size: 14px;
}
.whatsapp-icon {
  color: green;
}

.message-icon {
  color: #738aff;
}
.notification-toast {
  font-weight: bold;
  font-size: 14px;
}

.message-preview {
  flex: 0 0 auto; /* Prevent message preview from growing or shrinking */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.page-loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background-color: #00000073;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.border-left-0rem {
  border-left: 0rem;
}

.border-right-0rem {
  border-right: 0rem !important;
}

.border-radius-left-none {
  border-radius: 0.25rem 0rem;
}

.border-radius-right-none {
  border-radius: 0rem 0.25rem !important;
}

.chatWidget {
  max-height: 40rem;
  width: 30rem;
}

.media-preview {
  width: '50px';
  height: '50px';
}

.media-remove-btn {
  position: absolute;
  left: 0.5rem;
  bottom: 2.6rem;
}
.notification-container {
  max-width: 300px;
}

.delete-modal-icon {
  font-size: 9em;
  color: orange;
}

.alert-tab-actions {
  width: 70px;
}

.action-partition {
  height: 20px;
  width: 2px;
  background-color: #dee2e6;
}

.dashboard-dropdown-menu {
  overflow-y: scroll;
  max-height: 300px;
}

.on-call-btn {
  height: 4rem;
  width: 4rem;
  z-index: 10000;
  position: absolute;
  top: 9rem;
  right: 1rem;
  background-color: rgb(5, 123, 5);
  border-radius: 50%;
  box-shadow: 2px 4px 6px 2px rgba(0, 0, 0, 0.5);
}

.setting-sidebar {
  min-height: 80vh;
}

.p-dropdown-panel {
  z-index: 1050 !important;
}

.p-dropdown-items-wrapper {
  ul {
    padding-left: 0;
    margin: 0;
    padding: 0rem;
  }
}
ul {
  padding-left: 0rem !important;
}

.mb-0-1 {
  margin-bottom: 0.1rem;
}

.fw-500 {
  font-weight: 500;
}

.filter-btn {
  padding: 0rem 0.5rem !important;
}
