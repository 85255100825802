.dialpad-container {
  background-color: #f7f7f7;
}

.select-option {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.phone-input-container {
  width: 100%;
  margin-bottom: 40px;
  margin-top: 10px;
}

.keypad-container {
  background-color: #1a1a1b;
  margin: 12px;

  .keypad-container-body {
    background-color: #272729;
    padding: 10px;
  }
}

.key-pad {
  display: grid;
  margin: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  .keypad-button {
    background: #4e4e53;
    border-radius: 10px;
    padding: 0 12px;
    height: 45px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background: #6b6b70;
    }

    .number {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }

    .letters {
      // font-family: 'Montserrat';
      font-weight: lighter;
      font-size: 10px;
      text-align: center;
    }
  }
}
.invisible-input {
  background: transparent;
  border: none;
  outline: none;
  color: white; /* If you want the text to be invisible */
  width: 100%;
  padding: 5px 10px;
  font-size: 16px;
}

.invisible-input::placeholder {
  color: #ccc; /* Change this to the color you want for the placeholder */
}

.invisible-input:focus {
  outline: none; /* Prevents the outline from appearing on focus */
}

.number-pad {
  display: grid;
  margin-top: 0;
  margin-left: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  .keypad-button {
    background: #4e4e53;
    border-radius: 10px;
    padding: 0 12px;
    height: 45px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background: #6b6b70;
    }

    .number {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
    }
    .letters {
      // font-family: 'Montserrat';
      font-weight: lighter;
      font-size: 10px;
      text-align: center;
    }
  }
  .call-button {
    background: $success; /* Primary green */
    color: white;
    grid-column: 1 / 3;
    grid-row: 5 / 6;
    &:hover {
      background: #43af87; /* Darker green */
    }
  }

  /* Specific styles for clear button */
  .clear-button {
    background: #dc3545; /* Primary red */
    color: white;
    grid-column: 3 / 4;
    grid-row: 5 / 6;
    &:hover {
      background: #c82333; /* Darker red */
    }
  }
}

.end-call {
  width: 40px;
  height: 40px;
  background: $danger;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.status-dropdown {
  position: relative;
  left: 3rem;
  display: block;
  cursor: pointer;
  z-index: 2;
}

.status-display {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  // border: 1px solid #ccc;
  border-radius: 50px;

  .status-indicator {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;

    &.active {
      background-color: $success;
    }

    &.away {
      background-color: $warning;
    }

    &.busy {
      background-color: $danger;
    }

    &.offline {
      background-color: $secondary;
    }
  }
}

.status-dropdown-menu {
  position: absolute;
  right: 1px;
  margin-top: 5px;
  padding: 0;
  background-color: #333336;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  // z-index: 10000;
}

.status-dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #333336;
  border: none;
  &:hover {
    background-color: #4e4e53;
  }

  .status-indicator {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 8px;

    &.active {
      background-color: $success;
    }

    &.away {
      background-color: $warning;
    }

    &.busy {
      background-color: $danger;
    }

    &.offline {
      background-color: $secondary;
    }
  }
}

// CallControls.scss

.call-control-button {
  width: 40px;
  height: 40px;
  background: #4e4e53;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &.muted {
    background-color: #f0f0f0; /* Background color when muted */
  }

  &.on-hold {
    background-color: #f0f0f0; /* Background color when on hold */
  }

  &.active {
    background-color: #f0f0f0; /* Background color when keypad is open */
  }

  &.recording {
    background-color: #f0f0f0; /* Background color when recording */
  }
}
.call-control-button:hover {
  width: 40px;
  height: 40px;
  background: rgba(54, 54, 57, 0.27);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

//Call Widget

.call-widget-container {
  z-index: 99999;
  position: absolute;
  background-color: #1a1a1b;
  bottom: 0;
  right: 0;
  width: 350px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5), 0px 8px 64px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: 1px solid #434344;
  border-radius: 0.25rem;
}

.call-widget-card {
  background-color: #1a1a1b;
  margin-bottom: 0;
}

.call-widget-header {
  background-color: #333336;
  padding: 0px 10px;
}

.call-widget-body {
  padding: 0;
}

.call-widget-caller-info {
  display: flex;
  align-items: center;
  background-color: #272729;
  padding: 8px 10px;
}

.caller-avatar {
  background-color: #6c63ff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  margin-right: 10px;
  margin-left: 5px;
}

.caller-details {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 5px;
}

.call-widget-on-call {
  z-index: 99999;
  position: absolute;
  background-color: #333336;
  bottom: 0;
  right: 0;
  width: 400px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5), 0px 8px 64px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: 1px solid #434344;
  border-radius: 0.25rem;
}

.call-widget-on-call-info {
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.incoming-call-container {
  z-index: 99999;
  position: absolute;
  background-color: #333336;
  top: 0;
  right: 0;
  width: 350px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5), 0px 8px 64px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border: 1px solid #434344;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
}

.incoming-call-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.caller-avatar {
  background-color: #6c63ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  margin-right: 10px;
}

.incoming-call-actions {
  display: flex;
  gap: 20px;
}

.incoming-call-button {
  width: 70px;
}
